import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import { TOption } from "customHooks/useUtilities";
import { useRouter } from "next/router";
import React from "react";
import RadioIcon from "../RadioIcon";

type Props = {
  options: TOption[];
  selectedOption: TOption;
  onOptionChange: (option: TOption) => void;
  title?: string;
  swipeToClose?: boolean;
  showModalOffCanvas: boolean;
  handleCloseModalOffCanvas: () => void;
};
const FilterSelectModal: React.FC<Props> = ({
  options,
  selectedOption,
  swipeToClose = false,
  onOptionChange,
  title,
  handleCloseModalOffCanvas,
  showModalOffCanvas,
}) => {
  const { locale } = useRouter();
  return (
    <>
      <MolhamModalOffcanvas
        show={showModalOffCanvas}
        onHide={handleCloseModalOffCanvas}
        swipeToClose={swipeToClose}
        fullscreen
        header={{ label: title }}
        body={{
          style: {
            height: "100%",
          },
          className: "p-0 z-2002",
        }}
      >
        <div className="h-100">
          {options?.map((option, optionIdx) => {
            return (
              <div
                onClick={() => {
                  onOptionChange(option);
                }}
                className={`d-flex align-items-center justify-content-between w-100 py-0 ${
                  optionIdx === options.length - 1
                    ? "border-bottom-0"
                    : "border-bottom"
                }`}
                style={{
                  paddingInlineStart: "1rem",
                  paddingInlineEnd: "1.23rem",
                }}
                key={option.label[locale]}
              >
                <div className="d-flex flex-column py-3">
                  <label
                    htmlFor={option.label[locale]}
                    className={`mb-0 d-block cursor fs-base`}
                  >
                    {option.label[locale]}
                  </label>
                </div>
                <div>
                  <RadioIcon
                    checked={selectedOption?.value === option.value}
                    onClick={() => {
                      onOptionChange(option);
                    }}
                    id={option.label[locale]}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </MolhamModalOffcanvas>
    </>
  );
};

export default FilterSelectModal;
