import { MolhamLanguagesObject } from "apiTypes";
import { TFilter, TOption } from "customHooks/useUtilities";
import { produce } from "immer";
import { StateCreator, create } from "zustand";

// const resetters: (() => void)[] = [];

// const create = (<T extends unknown>(f: StateCreator<T> | undefined) => {
//   if (f === undefined) return create;
//   const store = _create(f);
//   const initialState = store.getState();
//   resetters.push(() => {
//     store.setState(initialState, true);
//   });
//   return store;
// }) as typeof _create;

// export const resetCheckoutStore = () => {
//   for (const resetter of resetters) {
//     resetter();
//   }
// };
// type ImmerStateCreator<CurrentSlice> = StateCreator<
//   FilterSlice,
//   [["zustand/immer", never]],
//   [],
//   CurrentSlice
// >;
type FilterNodeValue = number | string | null;
export type CheckboxFilterNode = {
  leaves: FilterNodeValue[];
  parent: FilterNodeValue | undefined;
  value: FilterNodeValue;
  selectedValue: "checked" | "unchecked";
  label: MolhamLanguagesObject;
  level: number;
};
type RadioFilterNode = {
  value: FilterNodeValue;
  label: MolhamLanguagesObject;
};
export type RadioFilter = {
  nodes: RadioFilterNode[];
  selectedValue: FilterNodeValue;
  selectedLabel: MolhamLanguagesObject;
};
type GenericFilter = CheckboxFilterNode[] | RadioFilter;

type FilterSlice = {
  filters?: { [key: string]: GenericFilter };
  draftFilters?: { [key: string]: GenericFilter };
  applyFilters: () => void;
  initializeFilters: (apiFilters: TFilter[]) => void;
  filterVisible: boolean;
  setFilterVisible: (visible: boolean) => void;
  handleCheckbox: (
    filterName: string,
    nodeValue: FilterNodeValue,
    checked?: "checked" | "unchecked"
  ) => void;
  handleRadio: (filterName: string, nodeValue: FilterNodeValue) => void;
  getFilterLabel: (filterName: string) => MolhamLanguagesObject[];
  getFilterValue: (filterName: string) => FilterNodeValue[];
  toQueryParams: () => { [key: string]: string };
  resetForNewRoute: () => void;
};

const useFilterStore = create<FilterSlice>((set, get) => ({
  resetForNewRoute: () =>
    set(
      produce((state: FilterSlice) => {
        state.filters = {};
        state.draftFilters = {};
        state.filterVisible = false;
      })
    ),
  filterVisible: false,
  filters: {},
  draftFilters: {},
  applyFilters: () =>
    set((state) => ({
      filters: state.draftFilters,
    })),
  initializeFilters: (apiFilters) =>
    set(
      produce((state: FilterSlice) => {
        if (state.filters?.[apiFilters[0].name]) {
        } else {
          apiFilters.forEach((filter) => {
            const filterName = filter.name;

            if (filter.type === "radio") {
              const filterNodes: RadioFilterNode[] = [];
              filter.options.forEach((option) => {
                filterNodes.push({
                  label: option.label,
                  value: option.value,
                });
              });
              state.filters[filterName] = {
                nodes: filterNodes,
                selectedValue: filterNodes[0].value,
                selectedLabel: filterNodes[0].label,
              } as RadioFilter;
            } else {
              const filterNodes: CheckboxFilterNode[] = [];
              const checkIfChildren = (option: TOption, level: number = 1) => {
                addNode(
                  option.label,
                  option.value,
                  null,
                  option.options?.map((v) => {
                    return v.value;
                  }) || [],
                  level
                );
                if (option.options) {
                  option.options.forEach((child) => {
                    if (child.options) {
                      checkIfChildren(child, level + 1);
                    } else {
                      addNode(
                        child.label,
                        child.value,
                        option.value,
                        child.options?.map((v) => {
                          return v.value;
                        }) || [],
                        level + 1
                      );
                    }
                  });
                }
              };
              const addNode = (
                label: MolhamLanguagesObject,
                value: FilterNodeValue,
                parent: FilterNodeValue | undefined,
                leaves: FilterNodeValue[],
                level: number
              ) => {
                filterNodes.push({
                  label,
                  value,
                  parent,
                  leaves,
                  selectedValue: "checked",
                  level,
                });
              };

              addNode(
                filter.options.find((v) => v.value === null)?.label ?? {
                  ar: "الكل",
                  de: "Alles",
                  en: "All",
                  es: "All",
                  fr: "tout",
                  tr: "All",
                },
                null,
                undefined,
                filter.options
                  .filter((v) => v.value !== null)
                  .map((v) => {
                    return v.value;
                  }),
                0
              );

              filter.options.forEach((option) => {
                if (option.value === null) return;
                checkIfChildren(option);
              });
              state.filters = {
                ...state.filters,
                [filterName]: filterNodes,
              };
            }
          });

          state.draftFilters = state.filters;
        }
      }),
      true
    ),
  setFilterVisible: (visible) =>
    set(() => ({
      filterVisible: visible,
    })),
  handleCheckbox: (filterName, nodeValue, overrideValue) => {
    const searchNodes = get().draftFilters[filterName] as CheckboxFilterNode[];
    const getNode = (value: FilterNodeValue) => {
      return searchNodes.findIndex((n) => n.value === value);
    };

    const getLeaves = (value: FilterNodeValue) => {
      return searchNodes[getNode(value)].leaves.map((v) => getNode(v));
    };
    const newValue =
      overrideValue !== undefined
        ? overrideValue
        : searchNodes[getNode(nodeValue)].selectedValue === "checked"
        ? "unchecked"
        : "checked";
    set(
      produce((state) => {
        const allNodes = state.draftFilters[filterName];
        const currenctNode = allNodes[getNode(nodeValue)];
        currenctNode.selectedValue = newValue;
      })
    );
    let node = (get().draftFilters[filterName] as CheckboxFilterNode[])[
      getNode(nodeValue)
    ];
    for (const leaf of node.leaves) {
      get().handleCheckbox(filterName, leaf, newValue);
    }
    while (node.parent !== undefined) {
      set(
        produce((state) => {
          const allNodes = state.draftFilters[filterName];
          const parentLeaves = getLeaves(node.parent);
          allNodes[getNode(node.parent)].selectedValue = parentLeaves.every(
            (leaf) => allNodes[leaf].selectedValue === "checked"
          )
            ? "checked"
            : "unchecked";
        })
      );
      node = (get().draftFilters[filterName] as CheckboxFilterNode[])[
        getNode(node.parent)
      ];
    }
    if (
      (get().draftFilters[filterName] as CheckboxFilterNode[]).every(
        (v) => v.selectedValue === "unchecked"
      )
    ) {
      get().handleCheckbox(filterName, null, "checked");
    }
  },
  handleRadio: (filterName, nodeValue) => {
    set(
      produce((state) => {
        state.draftFilters[filterName].selectedValue = nodeValue;
        state.draftFilters[filterName].selectedLabel =
          state.draftFilters[filterName].nodes[
            state.draftFilters[filterName].nodes.findIndex(
              (v) => v.value === nodeValue
            )
          ].label;
      })
    );
  },
  getFilterLabel: (filterName) => {
    const filter = get().draftFilters[filterName];

    if (filter instanceof Array) {
      const getNode = (value: FilterNodeValue) => {
        return filter.findIndex((n) => n.value === value);
      };
      return filter
        .filter((v) => v.selectedValue === "checked")
        .filter((v) => {
          if (v.parent !== undefined) {
            return filter[getNode(v.parent)].selectedValue === "unchecked";
          }
          return true;
        })
        .map((v) => {
          return v.label;
        });
    } else {
      return [filter.selectedLabel];
    }
  },
  getFilterValue: (filterName) => {
    const filter = get().draftFilters[filterName];

    if (filter instanceof Array) {
      const getNode = (value: FilterNodeValue) => {
        return filter.findIndex((n) => n.value === value);
      };
      return filter
        .filter((v) => v.selectedValue === "checked")
        .filter((v) => {
          if (v.parent !== undefined) {
            return filter[getNode(v.parent)].selectedValue === "unchecked";
          }
          return true;
        })
        .map((v) => {
          return v.value;
        });
    } else {
      return [filter.selectedValue];
    }
  },
  toQueryParams: () => {
    const filters = get().draftFilters;
    const queryParams: { [key: string]: string } = {};

    Object.keys(filters).forEach((filterName) => {
      get()
        .getFilterValue(filterName)
        .forEach((value) => {
          if (value === null) return;
          if (queryParams?.[filterName])
            queryParams[filterName] =
              queryParams[filterName] + "," + value.toString();
          else queryParams[filterName] = value.toString();
        });
    });
    return queryParams;
  },
}));

export { useFilterStore };
