
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import NoData from "../../components/ReuseableCompos/NoData";
import { API_URL } from "../../config";
import DonateLayout from "../../layout/DonateLayout";
import { MolhamListTargetable, PaginatableList } from "apiTypes";
import DonatePage from "components/ReuseableCompos/SharedDonatePages/DonatePage";
import SEO from "components/Seo";
import { DonatePageProvider } from "context/DonatePageContext";
import { TTargetType, TUtilities } from "customHooks/useUtilities";
import fs from "fs/promises";
import { GetStaticPaths, GetStaticProps } from "next";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import path from "path";
import { useContext, useEffect } from "react";
import { NavHeaderContext } from "../../context/NavHeaderContext";
import TabsLayout from "../../layout/TabsLayout";
import { getData } from "../../utils/getData";
type Props = {
    data: PaginatableList<MolhamListTargetable> | {
        error: string;
    };
    currentSection: TTargetType;
};
type Params = {
    donate: string;
};
const Donate = ({ data, currentSection }: Props) => {
    const router = useRouter();
    const { setHeaderNavMeta } = useContext(NavHeaderContext);
    const { t } = useTranslation();
    useEffect(() => {
        setHeaderNavMeta((prev) => ({
            ...prev,
            setBar: <TabsLayout currentSection={currentSection}/>
        }));
        return () => {
            setHeaderNavMeta((prev) => ({
                ...prev,
                setBar: null
            }));
        };
    }, [currentSection]);
    if ("error" in data && router.query) {
        return (<NoData actionBtn={{
                label: t(`clear_all_filters`),
                onClick() {
                    router.replace(router.pathname, router.asPath.split("?")[0], {
                        locale: router.locale
                    });
                }
            }}/>);
    }
    if ("error" in data) {
        return <NoData error={JSON.stringify(data?.error) as any}/>;
    }
    return (<>
      <SEO 
    // molhamImages={
    //   currentSection?.name?.includes("event")
    //     ? undefined
    //     : data?.data?.[0]?.preview_image
    // }
    title={currentSection?.page_metadata?.title?.[router.locale]} description={currentSection?.page_metadata?.description?.[router.locale]}/>
      <DonatePageProvider data={data} endPoint={currentSection?.page_api_endpoint_path} currentSection={currentSection}>
        <DonatePage data={data} endPoint={currentSection?.page_api_endpoint_path}/>
      </DonatePageProvider>
    </>);
};
Donate.Layout = DonateLayout;
export default Donate;
// export const getServerSideProps = async (ctx) => {
//   const locale = ctx.locale;
//   // const token = ctx.req.cookies.token;
//   const currency = ctx.req.cookies.currency;
//   ctx.res.setHeader(
//     "Cache-Control",
//     "public, s-maxage=60, stale-while-revalidate=59"
//   );
//   let ip =
//     ctx.req.headers["cf-connecting-ip"] ||
//     ctx.req.headers["x-forwarded-for"] ||
//     ctx.req?.connection?.remoteAddress;
//   let headers = {
//     "x-forwarded-for": ip,
//     locale,
//     currency,
//   };
//   headers = removeUndefined(headers);
//   let currentSection = null;
//   try {
//     const utilities = await JSON.parse(
//       await fs.readFile(
//         path.join(process.cwd(), "constants", "utilities.json"),
//         "utf8"
//       )
//     );
//     const mainSectionsName = Object.keys(utilities.target_types);
//     // loop over the targetTypes keys and check if there is no match redirect the user to /404
//     mainSectionsName.forEach((item) => {
//       if (
//         utilities.target_types[item]?.page_metadata?.path === ctx.params.donate
//       ) {
//         currentSection = utilities.target_types[item];
//       }
//     });
//   } catch (error) {}
//   if (currentSection?.page_metadata?.path !== ctx.params.donate) {
//     return {
//       redirect: {
//         permanent: false,
//         destination: `/404`,
//       },
//     };
//   }
//   let data = null;
//   const q = new URLSearchParams(ctx.query as Record<string, string>);
//   q.delete("donate");
//   q.sort();
//   try {
//     const res = await getData(
//       `${API_URL}/${currentSection?.page_metadata?.endpoint_path}${
//         currentSection?.page_metadata?.endpoint_path?.includes("?") ? "&" : "?"
//       }page=1&${q.toString()}`,
//       null,
//       headers,
//       undefined
//     );
//     data = res.data;
//   } catch (err) {
//     data = { error: JSON.stringify(err) };
//   }
//   // Pass data to the page via props
//   return {
//     props: {
//       data,
//       currentSection,
//     },
//   };
// };
const getStaticProps: GetStaticProps<Props, Params> = async (ctx) => {
    const locale = ctx.locale;
    let currentSection: TTargetType | null = null;
    try {
        const utilities: TUtilities = await JSON.parse(await fs.readFile(path.join(process.cwd(), "constants", "utilities.json"), "utf8"));
        // loop over the targetTypes keys and check if there is no match redirect the user to /404
        Object.values(utilities.target_types).map((value) => {
            if (value.page_metadata.path === ctx.params.donate) {
                currentSection = value;
            }
        });
    }
    catch (error) { }
    if (currentSection?.page_metadata?.path !== ctx.params.donate) {
        return {
            redirect: {
                permanent: false,
                destination: `/404`
            }
        };
    }
    let data: PaginatableList<MolhamListTargetable> | {
        error: string;
    } = null;
    try {
        const res = await getData<PaginatableList<MolhamListTargetable>>(`${API_URL}/${currentSection?.page_metadata?.endpoint_path}`, null, { locale });
        data = res.data;
    }
    catch (err) {
        // data = { error: JSON.stringify(err) };
        return {
            notFound: true,
            revalidate: 10
        };
    }
    // Pass data to the page via props
    return {
        props: {
            data,
            currentSection
        },
        revalidate: 30
    };
};
export const getStaticPaths: GetStaticPaths<Params> = async ({ locales }) => {
    let paths = [];
    try {
        const utilities: TUtilities = await JSON.parse(await fs.readFile(path.join(process.cwd(), "constants", "utilities.json"), "utf8"));
        // loop over the targetTypes keys and check if there is no match redirect the user to /404
        locales.forEach((locale) => {
            Object.values(utilities.target_types).map((value) => {
                paths.push({
                    params: {
                        donate: value.page_metadata.path
                    },
                    locale
                });
            });
        });
    }
    catch (error) { }
    return {
        paths,
        fallback: false
    };
};

    async function __Next_Translate__getStaticProps__1910a46d2c7__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[donate]/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910a46d2c7__ as getStaticProps }
  