import { useRouter } from "next/router";
import { useContext } from "react";
import { NavHeaderContext } from "../context/NavHeaderContext";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";

type Props = {
  section?: string;
  title: string;
};

const useHeaderTitleSetter = ({ section, title }: Props) => {
  const { setHeaderNavMeta } = useContext(NavHeaderContext);
  const { locale } = useRouter();
  //set the header title
  useIsomorphicLayoutEffect(() => {
    setHeaderNavMeta((prev) => ({ ...prev, section, title }));
    return () => {
      setHeaderNavMeta((prev) => ({ ...prev, section: null, title: null }));
    };
  }, [title, locale]);
};

export default useHeaderTitleSetter;
