import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

// converting some sesetive letters
const convertBreadcrumb = (string) => {
  return string
    .replace(/-/g, " ")
    .replace(/oe/g, "ö")
    .replace(/ae/g, "ä")
    .replace(/ue/g, "ü")
    .toUpperCase();
};
type Props = {
  className?: string;
  showHome?: boolean;
  breadCrumbM?: string;
};
const Breadcrumb = ({ className, showHome = true, breadCrumbM }:Props) => {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState<{
    breadcrumb: string;
    href: string;
  }[]>(null);

  const { t } = useTranslation("common");

  useEffect(() => {
    //   checking if there is router or not
    if (router) {
      const linkPath = router.asPath.split("/");
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => {
        return {
          breadcrumb: path,
          href: "/" + linkPath.slice(0, i + 1).join("/"),
        };
      });

      setBreadcrumbs(pathArray);
    }
  }, [router]);

  if (!breadcrumbs) {
    return null;
  }

  return (
    <nav aria-label="breadcrumbs">
      <ol
        className={` py-1 ${breadCrumbM || "my-2"} ${
          className || ""
        } breadcrumb align-items-center `}
      >
        {showHome && (
          <li className="breadcrumb-item align-items-center text-uppercase">
            <Link href="/" className="text-primary fs-xl fw-bold">

              <i className="fe-home mx-0"></i>

            </Link>
          </li>
        )}
        {breadcrumbs.map((breadcrumb) => {
          return (
            <li
              key={breadcrumb.href}
              className="breadcrumb-item active align-items-center text-uppercase"
            >
              <Link href={breadcrumb.href}>

                {t(convertBreadcrumb(breadcrumb.breadcrumb).toLowerCase())}

              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
