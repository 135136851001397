import CustomContentLoader from "../CustomContentLoader";

const DonateCardSkeleton = () => {
  return (
    <CustomContentLoader viewBox="0 0 400 560">
      <rect x="0" y="0" rx="10" ry="10" width="400" height="267" />
      <rect x="20" y="290" rx="4" ry="4" width="360" height="24" />
      <rect x="20" y="342" rx="8" ry="8" width="360" height="36" />
      <rect x="20" y="430" rx="8" ry="8" width="80" height="16" />
      <rect x="300" y="430" rx="8" ry="8" width="80" height="16" />
      <rect x="20" y="460" rx="4" ry="4" width="360" height="8" />
      <rect x="22" y="490" rx="6" ry="6" width="356" height="47" />
    </CustomContentLoader>
  );
};

export default DonateCardSkeleton;
