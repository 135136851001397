import Breadcrumbs, {
  BreadcrumbsProps,
} from "@marketsystems/nextjs13-appdir-breadcrumbs";
import { BreadcrumbJsonLd } from "next-seo";
import useTranslation from "next-translate/useTranslation";

import { useRouter } from "next/router";
type CustomBreadCrumbsProps = {
  transformedLabel?: (title: string) => string | Element | JSX.Element;
} & BreadcrumbsProps;
const CustomBreadCrumbs: React.FC<CustomBreadCrumbsProps> = ({
  transformedLabel,
  ...props
}) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const linkPath = router.asPath.split("/");
  linkPath.shift();

  const pathArray = linkPath.map((path, i) => {
    return {
      breadcrumb: path,
      href: "/" + linkPath.slice(0, i + 1).join("/"),
    };
  });
  const transformLabelForLD = (title: string): string => {
    const label = transformedLabel ? transformedLabel(title) : t(title);
    if (typeof label !== "string") return "";
    return label;
  };
  const LD = pathArray.map((item, i) => {
    return {
      position: i + 1,
      name: transformLabelForLD(item.breadcrumb),
      item:
        "https://molhamteam.com" +
        (router.locale === "ar" ? "" : "/" + router.locale) +
        item.href,
    };
  });
  return (
    <>
      <BreadcrumbJsonLd itemListElements={LD} />
      <Breadcrumbs
        {...props}
        activeItemClassName="active"
        listClassName="py-1 my-0 custom-breadcrumbs align-items-center"
        transformLabel={(title) => {
          if (title.toLocaleLowerCase() === "home")
            return <i className="fe-home mx-0 text-primary fs-xl"></i>;
          return transformedLabel ? (transformedLabel(title) as any) : t(title);
        }}
      />
    </>
  );
};

export default CustomBreadCrumbs;
