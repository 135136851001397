import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useContext } from "react";
import styled from "styled-components";
import DonateFilterBtn from "../components/ReuseableCompos/SharedDonatePages/DonateFilterBtn";
import DonateFilterOffCanvas from "../components/ReuseableCompos/SharedDonatePages/DonateFilterOffCanvas";
import DonateNavTabs from "../components/ReuseableCompos/SharedDonatePages/DonateNavTabs";
import { FilterOffcanvasContext } from "../context/FilterOffcanvasContext";
import { useModeContext } from "../context/ModeContext";
import useDonationSectionsMeta from "../customHooks/useDonationSectionsMeta";
import useStickyScroll from "../customHooks/useStickyScroll";

const TabsLayout = (props) => {
  const { donateNavRoutes } = useDonationSectionsMeta();

  const currentSection = props?.currentSection;

  const filtersObject = currentSection?.filters;

  const {
    showFilterOffCanvas,
    handleCloseFilterOffCanvas,
    handleShowFilterOffCanvas,
  } = useContext(FilterOffcanvasContext);

  const scroll = useStickyScroll(75);
  const matches = useMobile("lg");
  const { theme } = useModeContext();
  const { t } = useTranslation("common");

  const isEvents = currentSection?.name.includes("event");
  return (
    <div
      style={{
        background: "transparent",
        backgroundColor: "transparent",
      }}
    >
      <StyledDonateBar
        style={{ background: "transparent", backgroundColor: "transparent" }}
        className={`mt-0 ${matches ? "" : `border-top`}
        
        ${theme === "dark" && !matches && `border-bottom`}
        `}
        scroll={scroll}
      >
        <div className={`bg-trans  w-100`}>
          <div className="container px-0 px-lg-3">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="w-100">
                <DonateNavTabs donateNavRoutes={donateNavRoutes} />
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex d-lg-none">
                  {isEvents && (
                    <Link href={"/events/create#event-open-create-btn"}>
                      <button className="btn rounded-0">
                        <i className="fe-plus fs-xxl text-white ms-2"></i>
                      </button>
                    </Link>
                  )}
                 
                </div>
                <div className="d-lg-flex d-none">
                  {isEvents && (
                    <Link href={"/events/create#event-open-create-btn"}>
                      <button className="btn dark-border-end dark-border-start rounded-0">
                        {t("add_new_envents")}
                        <i className="fe-plus fs-xxl ms-2"></i>
                      </button>
                    </Link>
                  )}
                  <DonateFilterBtn
                    handleShowFilterOffCanvas={handleShowFilterOffCanvas}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDonateBar>

      <DonateFilterOffCanvas
        showFilterOffCanvas={showFilterOffCanvas}
        handleCloseDonateOffCanavas={handleCloseFilterOffCanvas}
        filtersObject={filtersObject}
      />
    </div>
  );
};

export default TabsLayout;

export const StyledDonateBar = styled.div<{
  scroll: boolean;
}>`
  .navbar-floating {
    z-index: ${({ scroll }) => (scroll ? "1005" : "5")};
    top: ${({ scroll }) => (scroll ? "70px" : "6px")};
  }
  @media (max-width: 991.98px) {
    .navbar-floating {
      top: ${({ scroll }) => (scroll ? "55px" : "0")};
    }
  }
  .rts___tab {
    padding: 5px 30px;
  }
  /* position: sticky; */
  /* z-index: 6 !important; */

  background-color: var(--white);
  @media (max-width: 991.98px) {
    /* position: relative !important;
    z-index: unset;
    top: unset; */
    .navbar-floating {
      position: relative !important;
      z-index: unset;
      top: unset;
    }
    .navbar-sticky.navbar-stuck {
      position: relative !important;
      z-index: unset;
      top: unset;
    }
    .sticky-lg-nav {
      position: fixed;
      z-index: ${({ scroll }) => (scroll ? "1005" : "5")};
    }
  }
`;
