import styled from "styled-components";
import Breadcrumb from "./BreadCrumb";

type Props = {
  title?: string;
  desc?: string;
  icon?: React.ReactNode;
  className?: string;
  p?: string;
  m?: string;
  jumbtronRightPadding?: boolean;
  breadCrumbM?: string;
  showBreadcrumb?: boolean;
  routeObject?: { label: string; href: string }[];
  customBreadCrumbs?: React.ReactNode;
  descM?: string;
  contentClassName?: string;
  borderBottom?: boolean;
};

const CustomJumbotron = ({
  title = null,
  desc = null,
  icon = null,
  className = null,
  p = null,
  m = null,
  jumbtronRightPadding = false,
  breadCrumbM = null,
  showBreadcrumb = true,
  customBreadCrumbs = null,
  descM = null,
  contentClassName = null,
  borderBottom = true,
  ...props
}: Props) => {
  return (
    <StyledBreadCrumbsContainer
      className={`container d-none d-lg-block ${
        borderBottom ? "border-bottom" : ""
      } ${p || "pb-0 pb-sm-4 px-sm-2 px-0"}  ${m || "mt-sm-3 mt-0"} ${
        className || ""
      }`}
      {...props}
    >
      <div
        style={{ paddingTop: "8px" }}
        className={`d-flex align-items-sm-start align-items-start text-sm-start ${contentClassName}`}
      >
        <div className="d-none d-lg-block">
          {icon && <div className="me-0 me-sm-2">{icon}</div>}
        </div>

        <div className={`${jumbtronRightPadding ? "ps-1 ps-lg-3" : ""}`}>
          {title && (
            <h1 className="display-6 p-0 text-uppercase my-0">{title}</h1>
          )}
          {desc && (
            <p
              className={`lead fs-lg ${
                descM || "mt-3 mb-2"
              } text-capitalize adjust-text-2-col lh17`}
            >
              {desc}
            </p>
          )}
          {/* <hr className="my-4" /> */}
          {showBreadcrumb && (
            <>
              {customBreadCrumbs ? (
                customBreadCrumbs
              ) : (
                <Breadcrumb breadCrumbM={breadCrumbM} className="my-0" />
              )}
            </>
          )}
        </div>
      </div>
    </StyledBreadCrumbsContainer>
  );
};

export default CustomJumbotron;

const StyledBreadCrumbsContainer = styled.div`
  @media (max-width: 767.98px) {
    .jumbtron-img-container {
      margin-top: 1rem;
    }
    img,
    .jumbtron-img-container {
      width: 64px !important;
      height: 64px !important;
    }
    .display-5 {
      font-size: 1.25rem;
    }
    p {
      font-size: 0.75rem !important;
    }
  }
`;
