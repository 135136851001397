import { MolhamListTargetable } from "apiTypes";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useMobile from "customHooks/useMobile";
import useRTL from "customHooks/useRTL";
import Image from "next/legacy/image";
import Link from "next/link";
import { memo, useEffect, useRef } from "react";
import styled from "styled-components";
import { tragetableFallbackImg } from "../../../config";
import { usePreferenceContext } from "../../../context/PreferencesContext";
import MolhamButton from "../MolhamButton";
import DonateCardSkeleton from "./DonateCardSkeleton";
import DonateProgressBar from "./DonateProgressBar";

type Props = {
  data?: MolhamListTargetable;
  onCardClick?: (id: string) => void;
  skeleton?: boolean;
  index?: number;
};
const TargetableCardMobile = ({
  data,
  onCardClick,
  skeleton,
  index,
}: Props) => {
  const { curr } = usePreferenceContext();

  const observer = new IntersectionObserver(
    (entries) => {
      const { isIntersecting, intersectionRatio } = entries[0];
      if (isIntersecting && intersectionRatio >= 0.5) {
        sessionStorage.setItem(`targetable-card`, index.toString());
      }
    },
    {
      threshold: [0, 0.25, 0.5, 0.75, 1],
    }
  );
  const ref = useRef(null);
  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref, observer]);

  const { formatNumbers } = useFormatNumbers();
  const breakTitle = useMobile("(max-height : 620px)", true);
  const breakBlock = useMobile("(max-height : 560px)", true);
  const breakDesc = useMobile("(max-height : 600px)", true);
  // const getDescBPs = useCallback(() => {
  //   if (typeof window !== "undefined") {
  //     const doc = document.documentElement;
  //     const val =
  //       Number(doc.style.getPropertyValue("--vh").split("px")[0]) * 100;
  //     if (val <= 760 && val > 730) {
  //       return "adjust-text-4-col";
  //     }
  //     if (val <= 730 && val > 700) {
  //       return "adjust-text-3-col";
  //     }
  //     if (val <= 700 && val > 670) {
  //       return "adjust-text-2-col";
  //     }
  //     if (val <= 670) {
  //       return "adjust-text-1-col";
  //     } else {
  //       return "adjust-text-5-col";
  //     }
  //   }
  // }, []);
  const isRTL = useRTL();
  const received = data?.amounts?.[curr?.code]?.received;
  const required = data?.amounts?.[curr?.code]?.required;
  const progressPrecentage = received ? received / required : 0;

  if (skeleton) {
    return (
      <TargetableCardMobileWrapper className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-grid-gutter">
        <DonateCardSkeleton />
      </TargetableCardMobileWrapper>
    );
  }
  return (
    <TargetableCardMobileWrapper
      ref={ref}
      id={"targetable-card-" + index}
      onClick={() => onCardClick(data?.id.toString())}
      className={`col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-grid-gutter px-0 scroll-snap-start`}
    >
      <div className={"d-flex flex-column  h-100 w-100"}>
        <Link
          prefetch={false}
          href={data.path}
          style={{
            minHeight: "fit-content",
          }}
        >
          <div className="donate-card">
            <Image
              unoptimized
              height={20}
              width={40}
              layout="responsive"
              // className="object-fit-cover"
              placeholder="blur"
              objectFit="cover"
              src={
                data?.preview_image?.thumbnails?.["16:9"] ||
                tragetableFallbackImg
              }
              blurDataURL={
                data?.preview_image
                  ? data?.preview_image?.blurry?.["16:9"]
                  : "https://miro.medium.com/max/928/1*mAMNQhfjxQ0OhYtysV3OmQ.jpeg"
              }
              alt={data?.contents?.title}
            />
          </div>
        </Link>

        <div className={`px-4 d-flex flex-column  h-100`}>
          <div className="pb-3 pt-2 text-center d-flex flex-column align-items-center justify-content-center">
            <h5 className={`m-0 p-0`}>
              <Link
                prefetch={false}
                href={data.path}
                className={`text-primary text-decoration-0 text-break adjust-text-2-col ${
                  breakTitle && "adjust-text-1-col"
                }`}
              >
                {data?.contents?.title}
              </Link>
            </h5>
            {!breakDesc && (
              <h3 className={`fs-md fw-medium p-0 m-0 mt-2`}>
                <Link
                  prefetch={false}
                  href={data.path}
                  className={`text-gray text-decoration-0 lh17 adjust-text-2-col`}
                >
                  {data?.contents?.description}
                </Link>
              </h3>
            )}
          </div>

          {/* {!breakBlock && (
            <div
              className={` border-top d-flex align-items-center justify-content-between w-100 py-3`}
            >
              <div className="d-flex align-items-center">
                <h3 className={`fs-md fw-medium m-0 me-1`}>
                  {data?.required_amount.label}
                </h3>
                <h3 className={`fs-md fw-bold m-0 text-primary`}>
                  <span className={!isRTL ? "me-1 " : "ms-1"}>
                    {formatNumbers(data?.required_amount?.amount?.[curr?.code])}
                  </span>
                </h3>
              </div>
              <div className="d-flex align-items-center">
                <h3 className={`fs-md fw-medium m-0 me-1`}>
                  {data?.contents?.short_name?.split(" ")[0]}
                </h3>
                <h3 className={`fs-md fw-bold m-0 text-primary`}>
                  {data?.contents?.short_name?.split(" ")[1]}
                </h3>
              </div>
            </div>
          )} */}
          <div className="mt-0 py-3 border-top">
            <DonateProgressBar
              progressPrecentage={
                data?.funding_progress_bar?.percentage || progressPrecentage
              }
              received={
                data?.funding_progress_bar?.blocks[0]?.amount?.[curr?.code]
              }
              left={data?.funding_progress_bar?.blocks[1]?.amount?.[curr?.code]}
              blocks={data?.funding_progress_bar?.blocks}
            />
          </div>

          <MolhamButton
            id="t9-mobile-card-btn"
            label={data?.donate_button_label}
            className={`w-100`}
            endIcon={data?.funded ? "fe-smile" : "fe-arrow-right "}
            bold
            fs="lg"
            href={data?.path}
          />
        </div>
      </div>
    </TargetableCardMobileWrapper>
  );
};

export default memo(TargetableCardMobile);
const TargetableCardMobileWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .card-product {
    position: relative;
    max-width: 25rem;
    box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.08);
  }

  .save-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
  }
  .card.card-hover:hover {
    border-color: unset !important;
    border: 1px solid var(--light-gray) !important;
  }
`;
