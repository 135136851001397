import { PaginatableList } from "apiTypes";
import useMobile from "customHooks/useMobile";
import { useRouter } from "next/router";
import { memo, useState } from "react";
import { SWRInfiniteConfiguration } from "swr/infinite";
import InfinteDataSoruceHandler from "../../SharedLayoutComponents/InfinteDataSoruceHandler";
import {
  default as DonateCard,
  default as TargetableCard,
} from "./TargetableCard";
import TargetableCardMobile from "./TargetableCardMobile";

const DonatePage = ({ endPoint, data: serverSideData }) => {
  const [donationId, setDonationId] = useState(null);

  const onCardClick = (id) => {
    setDonationId(id);
  };
  // const [calcdHeight, setCalcdHeight] = useState(0);
  const isMobile = useMobile("md");
  const { query } = useRouter();
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const contWrap = document.getElementsByClassName("content-wrap");
  //     if (isMobile) {
  //       (contWrap[0] as HTMLElement).style.overflow = "hidden";
  //     } else {
  //       (contWrap[0] as HTMLElement).style.overflow = "unset";
  //     }

  //     const appHeight = () => {
  //       const doc = document.documentElement;
  //       const calcd = window.innerHeight * 0.01 + "px";
  //       doc.style.setProperty("--vh", calcd);
  //       setCalcdHeight(window.innerHeight * 0.01);
  //     };

  //     window.addEventListener("resize", appHeight);

  //     appHeight();
  //     return () => {
  //       window.removeEventListener("resize", appHeight);
  //       if ((contWrap[0] as HTMLElement)?.style)
  //         (contWrap[0] as HTMLElement).style.overflow = "unset";
  //     };
  //   }
  // }, [isMobile]);
  const queryWithoutDonate = queryBuilder(query);
  const url = queryWithoutDonate
    ? `${endPoint}?${queryWithoutDonate}`
    : `${endPoint}`;
  const swrOptions: SWRInfiniteConfiguration<PaginatableList<any>> = {
    fallbackData: !queryWithoutDonate
      ? serverSideData?.error
        ? null
        : [serverSideData]
      : null,
    revalidateOnFocus: false,
    revalidateOnMount: !!queryWithoutDonate,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
  };

  // useEffect(() => {
  //   let startY = 0;
  //   if (typeof window !== "undefined" && isMobile === true) {
  //     document.addEventListener("touchstart", function (event) {
  //       startY = event.touches[0].clientY;
  //     });

  //     document.addEventListener("touchend", function (event) {
  //       const endY = event.changedTouches[0].clientY;
  //       const cardIdx = sessionStorage.getItem("targetable-card") || "0";
  //       if (endY < startY && Math.abs(endY - startY) > 50) {
  //         //scroll into view the next targetable card
  //         const doc = document.getElementById(
  //           `targetable-card-${parseInt(cardIdx) + 1}`
  //         );
  //         if (!doc) return;
  //         doc.scrollIntoView({
  //           behavior: "smooth",
  //           block: "end",
  //         });
  //       } else if (endY > startY && Math.abs(endY - startY) > 50) {
  //         //scroll into view the previous targetable card
  //         if (parseInt(cardIdx) <= 0) return;
  //         const doc = document.getElementById(
  //           `targetable-card-${parseInt(cardIdx) - 1}`
  //         );
  //         if (!doc) return;
  //         doc.scrollIntoView({
  //           behavior: "smooth",
  //           block: "end",
  //         });
  //       } else {
  //       }
  //     });
  //   }
  // });

  // useEffect(() => {
  //   if (typeof window !== "undefined" && isMobile !== true) return;
  //   const el = document.getElementsByTagName("html")[0];
  //   el.style.overflow = "hidden";

  //   return () => {
  //     el.style.overflow = "unset";
  //   };
  // }, [isMobile]);

  return (
    <>
      <InfinteDataSoruceHandler
        skeleton={DonateCard}
        skeletonProps={{ skeleton: true }}
        inifiteScrollProps={{ dontMobile: true }}
        endPoint={url}
        resourceName="data"
        skeletonAmount={6}
        getMoreSkeletonAmount={8}
        skeletonContainerClassName="row"
        inifiteScrollClassName="row"
        proxy={false}
        swrOptions={swrOptions}
      >
        {isMobile ? (
          <TargetableCardMobile onCardClick={onCardClick} />
        ) : (
          <TargetableCard onCardClick={onCardClick} />
        )}
      </InfinteDataSoruceHandler>
    </>
  );
};

export default memo(DonatePage);

const queryBuilder = (query) => {
  const queryObject = new URLSearchParams(query);
  queryObject.delete("donate");
  return queryObject.toString();
};
