import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import useFormatNumbers from "../../../customHooks/useFormatNumbers";
import { useIsomorphicLayoutEffect } from "../../../customHooks/useIsomorphicLayoutEffect";
type Props = {
  received?: number;
  left?: number;
  progressPrecentage?: number;
  blocks?: any;
};
const DonateProgressBar = ({
  received = 0,
  left,
  progressPrecentage,
  blocks,
}: Props) => {
  const { formatNumbers } = useFormatNumbers();

  const { t } = useTranslation("common");
  if (!blocks) {
    return null;
  }
  return (
    <>
      <div className="d-flex justify-content-between fs-md mt-1 mt-md-0">
        <>
          {blocks?.length <= 1 ? (
            <>
              <span className="v-centered justify-content-between fs-md">
                <span> {blocks[0]?.label}</span>
                <span className="fw-bold ms-2" dir="auto">
                  {formatNumbers(received)}
                </span>{" "}
              </span>
            </>
          ) : (
            <>
              <h3 className="fs-md mb-0 fw-normal">
                {blocks[0].label} &nbsp;
                <span className="fw-bold fs-md" dir="auto">
                  {formatNumbers(received)}
                </span>
              </h3>
              <h3 className="fs-md mb-0 fw-normal">
                {blocks[1].label} &nbsp;
                <span className="fw-bold fs-md" dir="auto">
                  {formatNumbers(left < 0 ? 0 : left)}
                </span>
              </h3>
            </>
          )}
        </>
      </div>
      <ProgressBar bgcolor={"var(--primary2)"} progress={progressPrecentage} />
    </>
  );
};

export default DonateProgressBar;
const ProgressBar = ({ bgcolor, progress }) => {
  const completedNum = parseInt(progress) < 3 ? 3 : parseInt(progress);
  const [first, setfirst] = useState(0);
  useIsomorphicLayoutEffect(() => {
    setfirst(1);
  }, [completedNum]);
  const containerStyles = {
    height: 10,
    width: "100%",
    backgroundColor: "var( --light-gray)",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${first === 0 ? 3 : completedNum}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
    transition: "width 1s ease-in-out",
  } as const;

  return (
    <div className="mt-2 mb-1" style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};
