import { DonateDetailsPageProps, DonatePageProps } from "ComponentTypes";
import { PropsWithChildren, createContext } from "react";

const DonatePageContext = createContext<
  DonatePageProps | DonateDetailsPageProps
>(null);
type Props = PropsWithChildren<DonatePageProps | DonateDetailsPageProps>;

const DonatePageProvider = ({ children, ...props }: Props) => {
  return (
    <DonatePageContext.Provider value={{ ...props }}>
      {children}
    </DonatePageContext.Provider>
  );
};

export { DonatePageContext, DonatePageProvider };
