import { NavHeaderContext } from "context/NavHeaderContext";
import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useContext } from "react";
import styled from "styled-components";
import CustomBreadCrumbs from "../components/ReuseableCompos/CustomBreadCrumbs";
import CustomJumbotron from "../components/ReuseableCompos/CustomJumbotron";
import useHeaderTitleSetter from "../customHooks/useHeaderTitleSetter";

const DonateLayout = ({ currentSection, ...props }) => {
  const { locale } = useRouter();
  const { t } = useTranslation("common");
  const title = currentSection?.page_metadata?.title[locale];
  const description = currentSection?.page_metadata?.description[locale];
  const { headerNavMeta } = useContext(NavHeaderContext);

  useHeaderTitleSetter({ section: "donate", title });
  const matches = useMobile("lg");
  return (
    <>
      <CustomJumbotron
        m=" "
        p="py-4"
        breadCrumbM="my-0"
        className={headerNavMeta.setBar ? "border-0" : "border-bottom"}
        title={title}
        desc={description}
        customBreadCrumbs={
          <CustomBreadCrumbs
            transformedLabel={(label) => {
              return label.toLocaleLowerCase() === currentSection?.page_path
                ? title
                : t(label.toLocaleLowerCase());
            }}
          />
        }
      />
      {/* </div> */}
      {!matches && (
        <div className="border-bottom">
          {headerNavMeta.setBar ? headerNavMeta.setBar : null}
        </div>
      )}

      <div className="container">
        <div className={matches ? "mt-donate-cont" : "mt-24rem"}>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default DonateLayout;

export const StyledDonateBar = styled.div<{ scroll: boolean }>`
  .navbar-floating {
    z-index: ${({ scroll }) => (scroll ? "1005" : "5")};
    top: ${({ scroll }) => (scroll ? "75px" : "0")};
  }
  @media (max-width: 991.98px) {
    .navbar-floating {
      top: ${({ scroll }) => (scroll ? "55px" : "0")};
    }
  }
  .rts___tab {
    padding: 5px 30px;
  }
  /* position: sticky; */
  /* z-index: 6 !important; */

  background-color: var(--white);
  @media (max-width: 991.98px) {
    /* position: relative !important;
    z-index: unset;
    top: unset; */
    .navbar-floating {
      position: relative !important;
      z-index: unset;
      top: unset;
    }
    .navbar-sticky.navbar-stuck {
      position: relative !important;
      z-index: unset;
      top: unset;
    }
    .sticky-lg-nav {
      position: fixed;
      z-index: ${({ scroll }) => (scroll ? "1005" : "5")};
    }
  }
`;
