import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Tab, Tabs } from "react-tabs-scrollable";
import useLastPath from "../../../customHooks/useLastPath";
import useRTL from "../../../customHooks/useRTL";

const DonateNavTabs = ({ donateNavRoutes }) => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const isRTL = useRTL();
  const [value, setValue] = useState(0);

  const lastPath = useLastPath();
  useEffect(() => {
    donateNavRoutes?.forEach((section, idx) => {
      if (section.page_path === lastPath) {
        setValue(idx);
        return;
      }
    });
  }, [router, donateNavRoutes]);
  // handling tab changes
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  return (
    <Tabs
      activeTab={value}
      isRTL={isRTL}
      
      leftBtnIcon={<FiChevronLeft size={"1.5em"} />}
      rightBtnIcon={<FiChevronRight size={"1.5em"} />}
      onTabClick={handleChange}
    >
      {donateNavRoutes?.map((item) => (
        <Tab
        
          onClick={() => router.replace(`/${item.page_path}`)}
          key={item.page_path}
          className="text-tab px-2"
          disabled={item.page_path === lastPath}
          //padding
          
        >
          <Link
            prefetch={false}
            href={`/${item.page_path}`}
            scroll={false}
            
            replace
            className={`nav-link text-capitalize fw-semibold`}>

            {t(item.page_metadata.tab_title[router.locale])}

          </Link>
          <span className="tab-indicator"></span>
        </Tab>
      ))}
    </Tabs>
  );
};

export default DonateNavTabs;
