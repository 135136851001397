import CustomOffCanvasHeader from "components/CustomOffCanvasHeader";
import CustomOffcanvas from "components/CustomOffcanvas";
import CustomOffcanvasBody from "components/CustomOffcanvasBody";
import useMobile from "customHooks/useMobile";
import { TFilter, TOption } from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import MolhamButton from "../MolhamButton";
import {
  CheckboxFilterNode,
  RadioFilter,
  useFilterStore,
} from "./DonateModal/FiltersState";
import CheckboxRoundedIcon from "./DonateModal/components/CheckboxRoundedIcon";
import FilterSelectModal from "./DonateModal/components/Select/FilterSelectModal";
import FilterCheckboxModal from "./FilterCheckboxModal";

type Props = {
  showFilterOffCanvas: boolean;
  handleCloseDonateOffCanavas: () => void;
  filtersObject: TFilter[];
};
const DonateFilterOffCanvas = ({
  filtersObject,
  showFilterOffCanvas,
  handleCloseDonateOffCanavas,
}: Props) => {
  const isMobile = useMobile("md");

  const [selectedFilter, setSelectedFilter] = useState<TFilter | null>(null);
  const {
    initializeFilters,
    draftFilters,
    handleCheckbox,
    handleRadio,
    toQueryParams,
    getFilterLabel,
    applyFilters,
    resetForNewRoute,
  } = useFilterStore((state) => ({
    initializeFilters: state.initializeFilters,
    draftFilters: state.draftFilters,
    handleCheckbox: state.handleCheckbox,
    handleRadio: state.handleRadio,
    getFilterLabel: state.getFilterLabel,
    toQueryParams: state.toQueryParams,
    applyFilters: state.applyFilters,
    resetForNewRoute: state.resetForNewRoute,
  }));
  const { locale, push, query, pathname } = useRouter();
  useEffect(() => {
    initializeFilters(filtersObject);
  }, [filtersObject]);
  useEffect(() => {
    resetForNewRoute();
    initializeFilters(filtersObject);
  }, [pathname, filtersObject]);
  const { t } = useTranslation();
  const closeModal = () => {
    setSelectedFilter(null);
  };
  const handleApply = () => {
    applyFilters();
    handleCloseDonateOffCanavas();
    push(
      {
        pathname: "/" + query["donate"],
        query: toQueryParams(),
      },
      undefined,
      { shallow: true }
    );
  };
  return (
    <>
      <CustomOffcanvas
        placement={isMobile ? "bottom" : "end"}
        show={showFilterOffCanvas}
        extraStyles={{ height: "100%" }}
        onHide={handleApply}
      >
        <CustomOffCanvasHeader
          onCloseBtnClick={handleCloseDonateOffCanavas}
          showButton
          centered={isMobile}
          customHeaderClassName="navbar-box-shadow dark-border-bottom px-3 d-flex"
        >
          {t(`filtering_sorting_options`)}
        </CustomOffCanvasHeader>
        <CustomOffcanvasBody className="p-3">
          {filtersObject.map((filter, i: number) => {
            return (
              <div key={i}>
                <label>{filter.label[locale]}</label>
                <div
                  onClick={() => {
                    setSelectedFilter(filter);
                  }}
                  className="w-100 cursor bg-secondary rounded-1 px-3 py-2 mb-3 d-flex flex-row justify-content-between align-items-center"
                >
                  <span>
                    {draftFilters?.[filter?.name] &&
                      getFilterLabel(filter.name)
                        .map((v) => v[locale])
                        .join(", ")}
                  </span>
                  <i className="fe-chevron-down"></i>
                </div>
              </div>
            );
          })}
        </CustomOffcanvasBody>
        <FilterSelectModal
          showModalOffCanvas={selectedFilter?.type === "radio"}
          swipeToClose={false}
          handleCloseModalOffCanvas={closeModal}
          title={selectedFilter?.label[locale]}
          options={selectedFilter?.options}
          onOptionChange={(option: TOption) => {
            handleRadio(selectedFilter?.name, option.value);
            setTimeout(() => {
              setSelectedFilter(null);
            }, 150);
          }}
          selectedOption={{
            label: (draftFilters?.[selectedFilter?.name] as RadioFilter)
              ?.selectedLabel,
            value: (draftFilters?.[selectedFilter?.name] as RadioFilter)
              ?.selectedValue,
          }}
        />
        <FilterCheckboxModal
          onClose={closeModal}
          showModal={selectedFilter?.type === "checkbox"}
          title={selectedFilter?.label[locale]}
        >
          <div className="px-1 py-1">
            <div className="d-flex flex-column">
              {selectedFilter?.name &&
                draftFilters?.[selectedFilter.name] &&
                Array.isArray(draftFilters[selectedFilter.name]) &&
                //@ts-ignore because we are sure that it is an array
                draftFilters[selectedFilter.name].map(
                  (option: CheckboxFilterNode, i) => {
                    const isLast =
                      //@ts-ignore
                      i === draftFilters[selectedFilter.name].length - 1;
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          handleCheckbox(selectedFilter?.name, option.value);
                        }}
                        className={`w-100 cursor  rounded-1 user-select-none py-2 ${
                          !isLast && "mb-2"
                        } d-flex  align-items-center`}
                        style={{
                          paddingInlineStart: option.level * 20 + 10,
                          paddingInlineEnd: option.level * 20 + 10,
                        }}
                      >
                        <CheckboxRoundedIcon
                          checked={option.selectedValue === "checked"}
                        />
                        <span className="ms-2">{option.label[locale]}</span>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </FilterCheckboxModal>
        {/* footer */}
        <div className="p-3">
          <MolhamButton
            id="filter-apply-btn"
            startIcon="fe-search"
            className="w-100"
            label={t(`view`)}
            onClick={handleApply}
          />
        </div>
      </CustomOffcanvas>
    </>
  );
};

export default DonateFilterOffCanvas;
