import { TMolhamImage } from "apiTypes";
import { NextSeo, NextSeoProps } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

type Props = {
  title?: string;
  description?: string;
  image?: string;
  molhamImages?: TMolhamImage;

  additinalProps?: NextSeoProps;
  fallbackImage?: string;
};

const SEO: React.FC<Props> = (props) => {
  const router = useRouter();
  const { t } = useTranslation("common");

  const title = props.title
    ? `${props.title} - ${t("molham_volunteering_team")}`
    : t("molham_volunteering_team");
  const description = props.description ?? `${t("molham_website_description")}`;
  const fallbackImage = props.image
    ? props.image
    : props.fallbackImage
    ? props.fallbackImage
    : "/og_default.jpeg";
  const images = props.molhamImages
    ? ["1:1", "3:2", "4:3", "16:9"].map((dim) => ({
        url: props.molhamImages.thumbnails?.[dim] || fallbackImage,
        alt: props.title,
      }))
    : [
        {
          url: fallbackImage,
          alt: props.title,
        },
      ];
  return (
    <NextSeo
      {...props.additinalProps}
      openGraph={{
        images: images,
        title: title,
        description: description,
        locale: router.locale,
        url: router.asPath,
        ...props.additinalProps?.openGraph,
      }}
      twitter={{
        cardType: "summary_large_image",
        handle: "@molhamteam",
        site: "@molhamteam",
      }}
      facebook={{
        appId: "240557123877022",
      }}
      title={title}
      description={description}
      canonical={
        "https://molhamteam.com" +
        (router.locale === "ar" ? "" : "/" + router.locale) +
        router.asPath
      }
    />
  );
};

export default SEO;
