import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";

const FilterCheckboxModal = ({ onClose, showModal, title, children }) => {
  return (
    <MolhamModalOffcanvas
      show={showModal}
      onHide={onClose}
      header={{ label: title }}
      body={{ className: "p-2" }}
    >
      <form style={{}}>{children}</form>
    </MolhamModalOffcanvas>
  );
};

export default FilterCheckboxModal;
