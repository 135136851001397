import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import useUtilities from "./useUtilities";

const useDonationSectionsMeta = () => {
  const [donateNavRoutes, setDonateNavRoutes] = useState([]);
  const [selectedSectionObject, setSelectedSectionObject] = useState(null);
  const { query, locale } = useRouter();
  const { data: utilities } = useUtilities();

  const donateRoutes = utilities?.target_types || {};
  const obj = useMemo(() => {
    return (
      utilities?.donation_sections.reduce((acc, item) => {
        acc[item.key] = item.target_types;
        return acc;
      }, {}) || {}
    );
  }, [utilities?.donation_sections]);

  useEffect(() => {
    let key = "";
    const entries = Object.entries(donateRoutes)?.map((x) => x[1]);
    entries?.forEach((item) => {
      if (item.page_path === query["donate"]) {
        setSelectedSectionObject(item);
        key = Object.keys(obj).find((key) => obj[key]?.includes(item.name));
      }
    });
    const obs = obj[key]?.map((subItem) => {
      return donateRoutes[subItem];
    });
    setDonateNavRoutes(obs);
  }, [query, obj, donateRoutes]);

  const sectionTitle =
    selectedSectionObject?.page_metadata.tab_title[locale] || "loading";
  const sectionDesc =
    selectedSectionObject?.page_metadata.description[locale] || "loading";

  return {
    sectionTitle,
    sectionDesc,
    selectedSectionObject,
    donateNavRoutes,
    setDonateNavRoutes,
  };
};

export default useDonationSectionsMeta;
