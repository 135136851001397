import { MolhamListTargetable } from "apiTypes";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useRTL from "customHooks/useRTL";
import Image from "next/legacy/image";
import Link from "next/link";
import { memo } from "react";
import styled from "styled-components";
import { tragetableFallbackImg } from "../../../config";
import { usePreferenceContext } from "../../../context/PreferencesContext";
import MolhamButton from "../MolhamButton";
import DonateCardSkeleton from "./DonateCardSkeleton";
import DonateProgressBar from "./DonateProgressBar";

type Props = {
  data?: MolhamListTargetable;
  onCardClick?: (id: string) => void;
  skeleton?: boolean;
};
const TargetableCard = ({ data, onCardClick, skeleton }: Props) => {
  const { curr } = usePreferenceContext();

  const { formatNumbers } = useFormatNumbers();

  const isRTL = useRTL();
  const received = data?.amounts?.[curr?.code]?.received;
  const required = data?.amounts?.[curr?.code]?.required;
  const progressPrecentage = received ? received / required : 0;

  if (skeleton) {
    return (
      <DonateCardWrapper className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-grid-gutter">
        <DonateCardSkeleton />
      </DonateCardWrapper>
    );
  }
  return (
    <DonateCardWrapper
      onClick={() => onCardClick(data?.id.toString())}
      className={`col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-grid-gutter `}
    >
      <div className={"card card-product border card-hover h-100"}>
        <Link
          prefetch={false}
          href={data.path}
          className={"card-img-top"}
          style={{
            minHeight: "fit-content",
          }}
        >
          <div className="donate-card">
            <Image
              unoptimized
              height={120}
              width={160}
              layout="responsive"
              // className="object-fit-cover"
              placeholder="blur"
              objectFit="cover"
              src={
                data?.preview_image?.thumbnails?.["4:3"] ||
                tragetableFallbackImg
              }
              blurDataURL={
                data?.preview_image
                  ? data?.preview_image?.blurry?.["4:3"]
                  : "https://miro.medium.com/max/928/1*mAMNQhfjxQ0OhYtysV3OmQ.jpeg"
              }
              alt={data?.contents?.title}
            />
          </div>
        </Link>

        <div
          className={`px-4 pb-4 pt-2  d-flex flex-column justify-content-between h-100 `}
        >
          <div className="pt-2 pb-3 text-center d-flex flex-column align-items-center justify-content-center">
            <h5 className={`m-0 p-0`}>
              <Link
                prefetch={false}
                href={data.path}
                className={`text-primary text-decoration-0 text-break adjust-text-2-col `}
              >
                {data?.contents?.title}
              </Link>
            </h5>

            <h3 className={`fs-md fw-medium p-0 m-0 mt-2`}>
              <Link
                prefetch={false}
                href={data.path}
                className={`text-gray text-decoration-0 lh17 adjust-text-2-col`}
              >
                {data?.contents?.description}
              </Link>
            </h3>
          </div>
          <div>
            {/* <div
              className={` border-top d-flex align-items-center justify-content-between w-100 py-3`}
            >
              <div className="d-flex align-items-center">
                <h3 className={`fs-md fw-medium m-0 me-1`}>
                  {data?.required_amount.label}
                </h3>
                <h3 className={`fs-md fw-bold m-0 text-primary`}>
                  <span className={!isRTL ? "me-1 " : "ms-1"}>
                    {formatNumbers(data?.required_amount?.amount?.[curr?.code])}
                  </span>
                </h3>
              </div>
              <div className="d-flex align-items-center">
                <h3 className={`fs-md fw-medium m-0 me-1`}>
                  {data?.contents?.short_name?.split(" ")[0]}
                </h3>
                <h3 className={`fs-md fw-bold m-0 text-primary`}>
                  {data?.contents?.short_name?.split(" ")[1]}
                </h3>
              </div>
            </div> */}

            <div className="mt-0 py-3 border-top">
              <DonateProgressBar
                progressPrecentage={
                  data?.funding_progress_bar?.percentage || progressPrecentage
                }
                received={
                  data?.funding_progress_bar?.blocks[0]?.amount?.[curr?.code]
                }
                left={
                  data?.funding_progress_bar?.blocks[1]?.amount?.[curr?.code]
                }
                blocks={data?.funding_progress_bar?.blocks}
              />
            </div>
          </div>
          <MolhamButton
            id="t9-card-btn"
            label={data?.donate_button_label}
            className={`w-100`}
            endIcon={data?.funded ? "fe-smile" : "fe-arrow-right "}
            bold
            fs="lg"
            href={data?.path}
          />
        </div>
      </div>
    </DonateCardWrapper>
  );
};

export default memo(TargetableCard);
const DonateCardWrapper = styled.div`
  @media (max-width: 768px) {
    height: calc(calc(var(--vh, 1vh) * 100) - 109px);
    padding-bottom: 62px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .meta-link {
      display: -webkit-box;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: none;
      overflow: hidden;
    }
  }
  @media (max-height: 700px) {
    .desc {
      display: none !important;
      font-size: 0 !important;
    }
    .meta-link {
      display: -webkit-box;
      -webkit-line-clamp: 0 !important;
      -webkit-box-orient: none;
      overflow: hidden;
    }
  }
  .card-product {
    position: relative;
    max-width: 25rem;
    box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.08);
  }
  .meta-link {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .save-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
  }
  .card.card-hover:hover {
    border-color: unset !important;
    border: 1px solid var(--light-gray) !important;
  }
`;
